<template>
  <div class="auth-wrapper auth-v2">
    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img style="max-width: 140px;" src="@/assets/images/sarit_logo.png">
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          lg="8"
          :style="cssOptions"
          class="d-none d-lg-flex align-items-center p-5"
      >

      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
          >
            Kings Mabati
          </b-card-title>

          <b-card-title class="mb-1">
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions to reset your password
          </b-card-text>

          <div style="padding: 10px" :class="{'alert alert-danger': hasError}">
            {{ error_message }}
          </div>

          <div style="padding: 10px"  :class="{'alert alert-success': hasSuccess}">
            {{ success_message }}
          </div>

          <!-- form -->
          <validation-observer ref="simpleRules" v-if="!hasSuccess">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                Send reset link
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { mapGetters, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import {FormWizard} from "vue-form-wizard";
import Button from "@/views/components/button/Button.vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    Loading
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      cssOptions:{
        backgroundImage: `url( ${require('@/assets/images/sarit_cover.jpg')} )`,
        backgroundSize: 'cover',
        backgroundBlendMode: 'multiply',
        backgroundColor: 'rgba(0, 0, 0,0.5)'
      },
      required,
      isLoading: false,
      fullPage: true,
      hasError:false,
      hasSuccess: false,
      error_message:"",
      success_message:"",
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },

  methods: {
    ...mapActions(["forgotPassword"]),

    sendForgotPasswordEmail(){
      this.isLoading = true;

      let self = this;

      console.log("Send email")

      this.forgotPassword({email: this.userEmail}).then(() => {
        self.isLoading = false;

        self.hasError = false;
        self.hasSuccess = true;
        self.success_message = "Email sent! Please check your email address.";

      })
          .catch(error => {
            self.isLoading = false;

            self.hasSuccess = false;
            self.hasError = true;
            self.error_message = error.message;

          })
    },
    validationForm() {
      console.log("Validating form");

      this.$refs.simpleRules.validate().then(success => {
        console.log(success);
        if (success) {
          this.sendForgotPasswordEmail();
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
